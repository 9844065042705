
const dyn = new Event('dynamicLoaded');

function init() {
  var data = {
    action: 'cnvs/dynamic/load-dynamic-content',
  }
   $.ajax({
    type: 'GET',
      url: window.location,
      dataType: 'json',
      data: data
  }).done(result => {
    if (result.loggedIn) {
      $('*[data-mast-user]').html(result.name)
      $('*[data-logged-out]').remove()
    } else {
      $('*[data-logged-in]').remove()
    }

    $('*[data-basket-counter]').html(result.basketTotal)
    $(`select[data-currency-switcher] option[value=${result.currency}]`).prop('selected', true)
    $(".siteHeader__mastRight").removeClass('siteHeader__mastRight--notLoaded')

    $('body').append(`<script type='text/javascript'>
      window.csrfTokenName = 'CSRF_TOKEN';
      window.csrfTokenValue = '${result.code}';
      window.canPlaceOrders = '${result.canPlaceOrders}';
      window.accountType = '${result.accountType}';
      window.language = '${result.language}';
      window.message = '${result.message}';
      window.currency = '${result.currency}';
      window.basketSubTotal = '${result.basketSubTotal}';
      window.basketDiscount = '${result.basketDiscount}';
    </script>`);
    $("input[name='CSRF_TOKEN']").val(result.code)

    // Alert / notice
    if (result.alertFlash) {
      $("[data-alert-message]").html(result.errorFlash)
      $("[data-notice-bar]").removeclass('hide')
    }

    // show distributor if exclusive
    if (result.distributorExclusitivty) {

      $('body').append(`<script type='text/javascript'>
         window.distributorName = '${result.distributorName}';
         window.distributorTelephone = '${result.distributorTelephone}';
         window.distributorExclusitivty = '${result.distributorExclusitivty}';
       </script>`);

      $('.proCallout').html(`<div class="callout bg-purple-vibrant border-purple-vibrant text-white rounded-0 mb-0"><div class="calloutContainer"><div class="copy text-center pr-0 ">Get hold of INNOluxe today, call your distributor ${result.distributorName} on <a class="underline" href="tel:${result.distributorTelephone}">${result.distributorTelephone}</a>. We're adding new pro only features soon!</div></div></div>`);
    }

    // Dispatch the event.
    document.dispatchEvent(dyn);
  })
}

init();
